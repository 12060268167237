'use client';

import React, { ReactElement } from 'react';
import { FiMenu as MenuIcon } from 'react-icons/fi';
import { useTranslations } from 'next-intl';
import { useNavigationTrigger } from './hooks/useNavigationTrigger';
import Button from '@/components/Button';
import classes from './navTrigger.module.scss';

const NavigationTrigger = (): ReactElement => {
    const t = useTranslations('navigationTrigger');
    const { handleOpenNavigation, isSearchOpen } = useNavigationTrigger();

    const rootClassName = isSearchOpen ? classes.visuallyHidden : classes.root;

    return (
        <Button className={rootClassName} aria-label={t('ariaLabel')} onClick={handleOpenNavigation}>
            <MenuIcon />
        </Button>
    );
};

export default NavigationTrigger;
