'use client';

import React, { ReactElement, useContext, ReactNode } from 'react';
import clsx from 'clsx';
import { HeaderContext } from '@/components/Header/context/headerContext';
import { useAppContext } from '@/lib/context';
import { GENERAL_HEADER_REF } from '@/constants/refIds';

import classes from '@/components/Header/header.module.scss';

const GeneralHeaderWrapper = ({ children }: { children: ReactNode }): ReactElement => {
    const { isHeaderVisible } = useContext(HeaderContext);
    const [
        {
            isSearchOpen,
            storeConfig: { isHeaderNewDesign },
        },
    ] = useAppContext();

    return (
        <header
            className={clsx(
                'general-header',
                classes.header,
                !isHeaderVisible && classes.headerInvisible,
                isSearchOpen && classes.headerSearchOpen,
                isHeaderNewDesign && 'white-header',
            )}
            id={GENERAL_HEADER_REF}
            data-mt-type="general-header"
        >
            {children}
        </header>
    );
};

export default GeneralHeaderWrapper;
