'use client';

import React, { ReactElement } from 'react';
import { FiSearch as SearchIcon } from 'react-icons/fi';
import { useAppContext } from '@/lib/context';
import { useTranslations } from 'next-intl';
import Trigger from '@/components/Trigger';

import classes from './searchTrigger.module.scss';

const SearchTrigger = (): ReactElement => {
    const [
        { isSearchOpen },
        {
            actions: { toggleSearch },
        },
    ] = useAppContext();

    const handleTriggerClick = () => {
        toggleSearch();
    };

    const t = useTranslations('searchTrigger');

    const searchText = t('search');

    const rootClassname = isSearchOpen ? classes.visuallyHidden : classes.zoom;

    return (
        <div className={rootClassname}>
            <Trigger addLabel={searchText} action={handleTriggerClick} aria-label={searchText}>
                <SearchIcon size={24} />
                <span className={classes.label}>{searchText}</span>
            </Trigger>
        </div>
    );
};

export default SearchTrigger;
